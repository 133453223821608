import classNames from "classnames";
import React, { useEffect, useState } from "react";

interface Props {
  email: string,
  children?: React.ReactNode,
  className?: string,
}

const BlockQuote: React.FC<Props> = (
  {
    email,
    children = null,
    className = null
  }
) => {
  const [isExposed, setIsExposed] = useState(false);

  useEffect(() => {
    setTimeout(() => {
        if (!isExposed) {
          setIsExposed(true);
        }
      }, 5000
    );
  }, []);

  return isExposed ? (
    <a href={`mailto:${email}`} target="_blank">
      {children ? children : email }
    </a>
  ) : (
    <a href="#" target="_blank">
      {children ? children : Array.from({ length: email.length }).map(() => "_").join("")}
    </a>
  );
};

export default BlockQuote;